import React, { useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import PlayButton from '../images/play-button.svg';

const ClVideo = React.forwardRef(({ videoId, video, cover, autoPlay }, ref) => {
  function onPlayClick(auto) {
    document.querySelector(`.play-btn-mask-${videoId}`).style.display = 'none';
    if (auto) {
      setTimeout(() => {
        document.getElementById(videoId).play();
      }, 800);
    } else {
      document.getElementById(videoId).play();
    }
  }

  useEffect(() => {
    const clVideo = document.getElementById(videoId);
    if (clVideo) {
      clVideo.volume = 0.3;
      if (autoPlay) {
        onPlayClick(autoPlay);
      }
      clVideo.addEventListener('pause', () => {
        document.querySelector(`.play-btn-mask-${videoId}`).style.display = 'flex';
      });
      // 点击进度条也会触发 pause 事件，故当视频自动播放时去掉按钮浮层
      clVideo.addEventListener('play', () => {
        document.querySelector(`.play-btn-mask-${videoId}`).style.display = 'none';
      });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleVideoPause: () => {
      document.getElementById(videoId).pause();
    },
  }));

  return (
    <div className="video-wrapper" ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={video}
        id={videoId}
        poster={
          cover ||
          '//convertlab-website.oss-cn-hangzhou.aliyuncs.com/images/backgroundjpg/video-poster.png'
        }
        className="video-fluid"
        controls="controls"
      />
      <div className={`play-btn-mask play-btn-mask-${videoId} ${autoPlay ? 'autoPlay' : ''}`} onClick={onPlayClick} aria-hidden="true">
        <img src={PlayButton} alt="play" />
      </div>
    </div>
  );
});

ClVideo.propTypes = {
  videoId: PropTypes.string,
  video: PropTypes.string,
  cover: PropTypes.string,
  autoPlay: PropTypes.bool,
};

export default ClVideo;
